<template>
<div>
    <center>
        <br>
       <b-row class="justify-content-center mt-3"> 
            <b-col cols="11" md="4" >
                <v-btn :color="tab == 0 ? '#1975B8' : '#29486F'" :class="isMobile ? 'mt-5' : 'mt-2'" size="small" block  dark style="text-transform:capitalize; border-radius: 10px;"   @click="cambiaTab(0)"> 
                Líder
                </v-btn>
            </b-col> 
        </b-row>
    </center>
     <hr style="margin-top:25px;">  
    <b-row class="justify-content-center">
        <b-col cols="11" md="7" lg="10" xl="10" class="mt-2">   
            <v-btn :color="region_pdv == 'Todas' ? '#1975B8' : '#29486F'"   size="small" block  dark style="text-transform:capitalize; border-radius: 10px;"  @click="cambiaRegion('Todas')"> 
                Regiones
            </v-btn>
        </b-col>
        <b-col cols="11" md="7" lg="10" xl="10" class="mt-2">   
            <v-btn :color="region_pdv == 'CENTRO FORANEO' ? '#1975B8' : '#29486F'"   size="small" block  dark style="text-transform:capitalize; border-radius: 10px;"  @click="cambiaRegion('CENTRO FORANEO')"> 
                Centro Foráneo
            </v-btn>
        </b-col>
        <b-col cols="11" md="7" lg="10" xl="10" class="mt-2">   
            <v-btn :color="region_pdv == 'CENTRO METROPOLITANO' ? '#1975B8' : '#29486F'"   size="small" block  dark style="text-transform:capitalize; border-radius: 10px;"  @click="cambiaRegion('CENTRO METROPOLITANO')"> 
                Centro Metropolitano
            </v-btn>
        </b-col>
        <b-col cols="11" md="7" lg="10" xl="10" class="mt-2">   
            <v-btn :color="region_pdv == 'NORTE' ? '#1975B8' : '#29486F'"   size="small" block  dark style="text-transform:capitalize; border-radius: 10px;"  @click="cambiaRegion('NORTE')"> 
                Norte
            </v-btn>
        </b-col>
        <b-col cols="11" md="7" lg="10" xl="10" class="mt-2">   
            <v-btn :color="region_pdv == 'OCCIDENTE' ? '#1975B8' : '#29486F'"   size="small" block  dark style="text-transform:capitalize; border-radius: 10px;"  @click="cambiaRegion('OCCIDENTE')"> 
                Occidente
            </v-btn>
        </b-col>
         <b-col cols="11" md="7" lg="10" xl="10" class="mt-2">   
            <v-btn :color="region_pdv == 'SUR' ? '#1975B8' : '#29486F'"   size="small" block  dark style="text-transform:capitalize; border-radius: 10px;"  @click="cambiaRegion('SUR')"> 
                Sur
            </v-btn>
        </b-col> 
        <b-col cols="11" md="7" lg="4" xl="4" v-if="isMobile">
            <input v-model="buscar_lider" type="text" class="form-control mt-3" placeholder="Buscar" style="border-radius: 10px; height: 75%;"> 
        </b-col>
    </b-row>  
    <b-row class="justify-content-center mt-5">
            <b-col cols="11" md="6" lg="10" xl="10" class="text-center mt-3"> 
                <center v-if="loader">
                    <SpinnerLoader/>
                    <p style="color:#000;">Procesando información, no cierres el navegador <br> espera un momento...</p>
                </center>  
                <template  v-if="tab == 0"> 
                  <div class="table-responsive"  id="global">
                    <b-row class="justify-content-center mt-2">
                     <b-col cols="12" md="6" class="mt-2"> 
                         <div v-for="(ran, index) in Lider" :key="ran.id + '-lider'" >
                          <b-card border-variant="light" text-variant="white" class="text-center mt-5 shadow"  header-tag="header" >
                             <template #header>
                                <h4 class="mb-0"  style="font-size:18px;"> {{ran.lider}}   </h4>
                            </template>
                            <b-card-text>
                                <b-row class="justify-content-center" style="margin-top:-90px;">
                                    <b-col cols="10"  style="margin-top:-5px;"> 
                                            <br > <br > <br><br v-if="isMobile">  
                                        <template v-if="!buscar_lider">
                                           <img v-if="index < 10" :src="'https://convencioncomercial.com.mx/plato/'+ index + '.png'" class="rounded" alt="Image" style="width:25%; margin-top:-4px;">  
                                            <h5 v-if="index > 9" style="color: #0c273a; margin-top:7px;  font-size:28px;" >
                                                <b>{{index + 1  }}</b>
                                            </h5>  
                                        </template>
                                        <template v-else>
                                            <img v-if="ran.lugar < 10" :src="'https://convencioncomercial.com.mx/plato/'+ (ran.lugar - 1)  + '.png'" class="rounded" alt="Image" style="width:25%; margin-top:-4px;">  
                                            <h4 v-if="ran.lugar > 9" style="color: #0c273a; margin-top:7px;  font-size:28px;" >
                                               <b> {{ran.lugar}}</b>
                                            </h4> 
                                        </template>
                                         <br > 
                                         <h1 style="color: #0c273a;"><b>Puntos: {{ran.total}}</b></h1>
                                    </b-col>
                                </b-row>
                                <b-row class="justify-content-center" style="margin-top:20px;"> 
                                        <b-col cols="12">
                                            <b  style="color: #0c273a; font-size:20px;">Cumplimientos</b> 
                                                <b-row  class="justify-content-center mt-2">
                                                    <b-col cols="8">
                                                        <b-row  class="justify-content-center mt-2">
                                                            <b-col cols="3" >
                                                                <center>
                                                                    <span style="text-transform:capitalize; font-size:12px;  color:#0b2739;"><b>Marz</b></span>
                                                                    <h4 style="color: #0c273a; font-size:16px;" class="mt-2" >
                                                                        {{ran.marzo}} 
                                                                    </h4> 
                                                                </center>
                                                            </b-col> 
                                                            <b-col cols="3" >
                                                                <center>
                                                                    <span style="text-transform:capitalize; font-size:12px;  color:#0b2739;"><b>Abril</b></span>
                                                                    <h4 style="color: #0c273a; font-size:16px;"  class="mt-2">
                                                                        {{ran.abril}} 
                                                                    </h4> 
                                                                </center>
                                                            </b-col>
                                                            <b-col cols="3" >
                                                                <center>
                                                                    <span style="text-transform:capitalize; font-size:12px;  color:#0b2739;"><b>Mayo</b></span>  
                                                                    <h4 style="color: #0c273a; font-size:16px;"  class="mt-2">
                                                                        {{ran.mayo}} 
                                                                    </h4> 
                                                                </center>
                                                            </b-col>
                                                            <b-col cols="3" >
                                                                <center>
                                                                    <span style="text-transform:capitalize; font-size:12px;  color:#0b2739;  margin-left: -10px;"><b>Junio</b></span>
                                                                    <h4 style="color: #0c273a; font-size:16px;"  class="mt-2">
                                                                        {{ran.junio}} 
                                                                    </h4> 
                                                                </center>
                                                            </b-col>  
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="4">
                                                        <b-row  class="justify-content-center mt-2"> 
                                                            <b-col cols="6" >
                                                                <center>
                                                                    <span style="text-transform:capitalize; font-size:12px;  color:#0b2739;  margin-left: -10px;"><b>Julio</b></span>
                                                                    <h4 style="color: #0c273a; font-size:16px;"  class="mt-2">
                                                                        {{ran.julio}} 
                                                                    </h4> 
                                                                </center>
                                                            </b-col> 
                                                            <b-col cols="6" >
                                                                <center>
                                                                    <span style="text-transform:capitalize; font-size:12px;  color:#0b2739;  margin-left: -10px;"><b>Agost</b></span>
                                                                    <h4 style="color: #0c273a; font-size:16px;"  class="mt-2">
                                                                        {{ran.agosto}} 
                                                                    </h4> 
                                                                </center>
                                                            </b-col> 
                                                        </b-row>
                                                    </b-col> 
                                                </b-row>  
                                        </b-col> 
                                    </b-row>
                            </b-card-text>
                        </b-card>
                        </div> 
                     </b-col>
                    </b-row>
                   </div>  
                </template> 
            </b-col>
        </b-row>
</div>
</template>

<script>
function compare(a, b) {
  if (a.total > b.total) {
    return -1;
  }
  if (a.total < b.total) {
    return 1;
  }
  return 0;
}

import SpinnerLoader from "../../utils/SpinnerLoader.vue";
export default {
  name: 'EspecialistaMovil',
  components:{
    SpinnerLoader
  },
  data(){
    return{
      isMobile: this.$mobile(),
      loader:false,
      arrayLider:[],
      arrayKams:[], 
      arrayLider2:[],
      arrayKams2:[], 
      tab:0,
      colors:'#83c32d',
      buscar_lider:"",
      buscar_kams:"", 
      vermas:10,
      region_pdv:"Todas",
      CentroF:[],
      CentroM:[],
      Occidente:[],
      Norte:[],
      Sur:[],
      Array:[]
    }
  },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    },
    Kams(){
        if (this.buscar_kams == '') {          
                return this.arrayKams;
            }else{
                return   this.arrayKams2.filter(item => {
                        return   item.kams?.toLowerCase().includes(this.buscar_kams.toLowerCase())
                        ;
                });
            }
    },
    Lider(){
        if (this.buscar_lider == '') {          
                return this.Array
            }else{
                return   this.Array.filter(item => {
                        return   item.lider?.toLowerCase().includes(this.buscar_lider.toLowerCase())
                        ;
                });
            }
    }, 
  },
  methods:{
    cambiaRegion(id){
         this.region_pdv = id;
         this.buscar_lider = "";
         switch (this.region_pdv ) {
            case "Todas":
                this.Array = this.arrayLider2; 
            break; 
            case "CENTRO FORANEO":
                 this.Array = this.CentroF; 
            break; 
            case "CENTRO METROPOLITANO":
                this.Array = this.CentroM; 
            break; 
            case "NORTE":
                this.Array = this.Norte; 
            break; 
            case "OCCIDENTE":
                this.Array = this.Occidente; 
            break; 
            case "SUR":
                this.Array = this.Sur; 
            break; 
         }
    },
    cambiaTab(id){
        this.cambiaRegion('Todas');
        this.tab = id;
        this.buscar_kams = "";
        this.buscar_lider = "";
    },
    subirArriba(){
      // this.$scrollTo("#subir");
       window.scrollTo(0, -500);
    },
    getcampanas(id){
      this.loader = true;
      this.vermas = id;
      this.buscar_kams = '';
      this.buscar_lider = ''; 
      var url= 'auth/rankinespecialista?vermas='+ id;
        this.$api.get(url).then(
            ({data}) => {
                console.log(data);
                this.arrayLider = data.lider.sort(compare); 
                this.arrayLider2 = data.lider2.sort(compare); 
                this.CentroF = data.centrof.sort(compare); 
                this.CentroM = data.centrom.sort(compare); 
                this.Occidente = data.occidente.sort(compare); 
                this.Norte = data.norte.sort(compare); 
                this.Sur = data.sur.sort(compare); 
                this.arrayKams = data.kams.sort(compare); 
                this.arrayKams2 = data.kams2.sort(compare); 
                this.cambiaRegion('Todas');
                this.loader = false;
            }
        );
    },
    regresarHome(){
        this.$bus.$emit("cambia_fondo2", true)
        this.$router.push("/"); 
    }
  },
  mounted(){
    this.$bus.$emit("cambia_fondo", true)
            if (this.isMobile)
      {
        this.$bus.$emit("cambi_fo9", true)
        //  window.scrollTo(0, -200);     
      } 
    this.getcampanas(this.vermas);
  }
}
</script>
<style>
.flexbox {
  align-items: center;
  display: flex;
  height: 42px;
  justify-content: center;
  width: auto;
}

.flexbox2 {
  align-items: center;
  display: flex;
  height: 45px;
  justify-content: center;
  width: auto;
}

#outer_wrapper {  
    overflow: scroll;  
    width:100%;
}
#outer_wrapper #inner_wrapper {
    width:1000px; /* If you have more elements, increase the width accordingly */
}
#outer_wrapper #inner_wrapper div.box { /* Define the properties of inner block */
    width: 250px;
    height:300px;
    float: left;
    margin: 0 4px 0 0;
    border:1px grey solid;
}


.card-header { 
   background-color: #0c273a;   
   border-radius: 3rem;
}

.card-body {
 background: #fff;
 border-radius: 3rem;
}

.card{
 border-radius: 3rem;
--bs-border-radius: 3rem;
}

#global {
	height: 1000px;
	width: 100%;
	border: 1px solid #dddddd00;
	background: #f1f1f100;
	overflow-y: scroll;
}
</style>